import { useEffect, useState } from "react";
import { useAppDispatch } from "store/hooks";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import _ from "lodash";
import { PipelineModel } from "models/pipelines.model";
import { getPipelineDesignerPageRoute } from "routes/routesHelper";
import { useHistory } from "react-router-dom";
import {
  deletePipeline,
  fetchNodesOfPipeline,
  fetchPipelines,
  patchPipeline,
  postDuplicatePipeline,
} from "helpers/apis/pipelines";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import DuplicateArchivePopover, {
  DuplicateArchivePopoverConfirmDialogState,
} from "components/Internal/Popovers/DuplicateArchivePopover";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";

type Props = {
  pipelines: PipelineModel[];
  setPipelines: (pipelines: PipelineModel[]) => void;
  searchValue?: string;
};

const PipelineManagerTable = ({
  pipelines,
  setPipelines,
  searchValue,
}: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [confirmDialogState, setConfirmDialogState] =
    useState<DuplicateArchivePopoverConfirmDialogState>({
      show: false,
      action: "duplicate",
      itemId: null,
    });
  const [isLoading, setIsLoading] = useState(false);

  // Fetch pipelines
  useEffect(() => {
    fetchPipelines(dispatch).then((data) => {
      setPipelines(data);
    });
  }, []);

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Pipeline name",
      span: 30,
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        const date = new Date(pipeline?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 20,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <UserGroupSelection
            selectedUserGroup={pipeline?.user_group}
            onChanges={(newUserGroup: string) =>
              patchPipeline(
                pipeline?.id,
                { user_group: newUserGroup },
                dispatch,
                setIsLoading,
              ).then(() => updatePipelines())
            }
          />
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 25,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <div className="w-full">
            <div
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  getPipelineDesignerPageRoute({ pipeline_id: pipeline?.id }),
                );
              }}
              className="button-layer float-right"
            >
              Go to pipeline
            </div>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 5,
      cell: (row) => {
        const pipeline = row as PipelineModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse"
          >
            <DuplicateArchivePopover
              item={pipeline}
              setConfirmDialogState={setConfirmDialogState}
            />
          </div>
        );
      },
    },
  ];

  const handleDialogConfirm = () => {
    if (confirmDialogState.action === "duplicate") {
      duplicatePipeline();
    } else if (confirmDialogState.action === "archive") {
      archivePipeline();
    } else {
      console.error("Action not found!");
    }
    setConfirmDialogState({ ...confirmDialogState, show: false });
  };

  // A function to call the duplicate pipeline endpoint
  const duplicatePipeline = () => {
    if (_.isNull(confirmDialogState.itemId)) return;
    const pipelineName = _.find(pipelines, ["id", confirmDialogState.itemId])
      ?.name;
    if (!pipelineName) {
      snackbarHelper("Pipeline not found!", "error");
      return;
    }

    fetchNodesOfPipeline(
      {
        pipelineID: confirmDialogState.itemId,
      },
      dispatch,
    ).then((nodes) => {
      postDuplicatePipeline(
        {
          name: pipelineName,
          nodes: nodes,
        },
        dispatch,
        setIsLoading,
      ).then(() => updatePipelines());
    });
  };

  // A function to call the delete pipeline endpoint
  const archivePipeline = () => {
    if (_.isNull(confirmDialogState.itemId)) return;
    deletePipeline(
      { pipelineID: confirmDialogState.itemId },
      dispatch,
      setIsLoading,
    ).then(() => updatePipelines());
  };

  const updatePipelines = () => {
    fetchPipelines(dispatch).then((data) => {
      setPipelines(data);
    });
  };

  const renderConfirmationDialog = () => {
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to ${
          confirmDialogState.action
        } the pipeline 
                "${_.find(pipelines, ["id", confirmDialogState.itemId])
                  ?.name}"?`}
        confirmButtonText="Confirm"
        confirmButtonClass={
          confirmDialogState.action === "duplicate"
            ? "button-layer bg-paletteGreen hover:bg-paletteGreen-dark text-white"
            : ""
        }
        handleOnSuccess={() => handleDialogConfirm()}
      />
    );
  };
  return (
    <>
      <DataTable
        rows={pipelines as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        searchPlaceholder="Search pipeline name"
        isLoading={isLoading}
        searchValue={searchValue}
      />
      {renderConfirmationDialog()}
    </>
  );
};

export default PipelineManagerTable;
