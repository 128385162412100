export interface FormModel {
  [key: string]: FormFieldModel;
}

export type FormFieldModel =
  | TextFieldModel
  | NumberFieldModel
  | SelectFieldModel
  | SingleSliderFieldModel
  | BooleanFieldModel;

export interface BaseFormFieldModel {
  key: string;
  value: unknown;
  label?: string;
  placeholder?: string;
  description?: string;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  isErrored?: boolean;
  error?: string | null;
}

export interface BaseFormFieldStyleModel {
  wrapper?: string;
  field?: string;
  labelAndFieldOnTheSameLine?: boolean;
  hideRequiredAsterisk?: boolean;
}

export interface TextFieldModel extends BaseFormFieldModel {
  type: "text" | "textarea";
  value: string;
  settings?: {
    minimumTextLength?: number;
    maximumTextLength?: number;
    isEmail?: boolean;
    notEqualTo?: string[];
  };
  style?: BaseFormFieldStyleModel;
}

export interface NumberFieldModel extends BaseFormFieldModel {
  type: "number";
  value: number;
  settings?: {
    minimumValue?: number;
    maximumValue?: number;
    step?: number;
  };
  style?: BaseFormFieldStyleModel;
}

export interface SingleSliderFieldModel extends BaseFormFieldModel {
  type: "single_slider";
  value: number;
  min: number;
  max: number;
  settings?: {
    step?: number;
    stepInteger?: boolean;
    numberOfSteps?: number;
    renderNumberInput?: boolean;
    hideUnderSliderLabels?: boolean;
    labelUnderSlider?: string;
  };
  style?: SingleSliderFieldStyleModel;
}

export interface SingleSliderFieldStyleModel extends BaseFormFieldStyleModel {
  track?: string;
  rail?: string;
}

export interface SelectFieldModel extends BaseFormFieldModel {
  type: "select";
  value: string;
  options: SelectFieldOptionModel[];
  style?: BaseFormFieldStyleModel;
}

export interface SelectFieldOptionModel {
  value: string | number | null;
  label: string;
  icon?: SelectFieldIcons;
}

export enum SelectFieldIcons {
  AiIcon = "aiIcon",
}

export interface BooleanFieldModel extends BaseFormFieldModel {
  type: "boolean";
  value: boolean | null;
  style?: BooleanFieldModelStyleModel;
}

export interface BooleanFieldModelStyleModel extends BaseFormFieldStyleModel {
  size?: "small" | "medium" | "large";
}
