import { AxiosError, AxiosResponse } from "axios";
import {
  APIFetchAxios,
  APIPostWithBodyAxios,
} from "components/UtilComponents/Auth";
import { ImportJobsModel } from "models/importJobs.model";
import { Dispatch } from "@reduxjs/toolkit";
import snackbarHelper from "components/Helpers/snackbarHelperFn";

/**
 * An endpoint to fetch tags for a given dataset id
 * @param dispatch The dispatch function
 * @returns A list of importJobs
 */
export const fetchImportJobsAPI = async (
  dispatch: Dispatch,
): Promise<AxiosResponse<ImportJobsModel[]>> => {
  return await APIFetchAxios("/importJobs")
    .then((response: AxiosResponse) => {
      snackbarHelper("Import jobs fetched successfully!");
      return Promise.resolve(response);
    })
    .catch((error: AxiosError<any>) => {
      snackbarHelper("Error fetching import jobs", "error");
      return Promise.reject(error);
    });
};

/**
 * An endpoint to create a new import job from an upload job
 * @param uploadJobID The id of the upload job
 * @param userGroup The user group of the user
 * @param datasetName The name of the dataset
 * @param compress Whether to compress the medias
 * @param anonymize Whether to anonymize the medias
 * @returns The id of the created import job
 */
export const postImportJobsAPI = async (
  uploadJobID: string,
  userGroup: string | null,
  datasetName: string,
  compress: boolean,
  anonymize: boolean,
): Promise<AxiosResponse<ImportJobsModel>> => {
  const response = await APIPostWithBodyAxios("/importJobs", {
    upload_job_id: uploadJobID,
    user_group: userGroup,
    dataset_name: datasetName,
    compress: compress,
    anonymize: anonymize,
  });
  return response;
};
