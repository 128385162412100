import { useKeycloak } from "@react-keycloak/web";
import ConfirmDialog from "components/Internal/Dialogs/ConfirmDialog";
import EditName from "components/Internal/Inputs/EditName";
import SharedByTeamLabel from "components/Internal/Labels/SharedByTeamLabel";
import StatusLabel from "components/Internal/Labels/StatusLabel";
import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";
import UserGroupSelection from "components/UtilComponents/UserGroupSelection";
import { snakeCaseToText } from "components/utilFunctions";
import {
  deleteMLAnnotationModel,
  patchMLAnnotationModel,
} from "helpers/apis/mlAnnotationModel";
import _ from "lodash";
import {
  MLAnnotationModel,
  MLAnnotationModelStatus,
} from "models/mlAnnotationModel.model";
import AIModelManagerTableActions from "Pages/AIManager/AIModelManager/AIModelManagerTableActions";
import { useRef, useState } from "react";
import { fetchAIModelsStore } from "store/aiManagerSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

export interface AIModelManagerPopoverConfirmDialogState {
  show: boolean;
  action: "archive";
  mlAnnotationModelID: string | null;
}

interface Props {
  setOverviewMlAnnotationModel: (mlAnnotationModel: MLAnnotationModel) => void;
}

const AIModelManagerTable = ({ setOverviewMlAnnotationModel }: Props) => {
  const keycloak = useKeycloak();
  const dispatch = useAppDispatch();
  const aiManagerSlice = useAppSelector((state) => state.aiManagerSlice);

  const [confirmDialogState, setConfirmDialogState] =
    useState<AIModelManagerPopoverConfirmDialogState>({
      show: false,
      action: "archive",
      mlAnnotationModelID: null,
    });

  const inputRefArray = useRef<{ [key: string]: HTMLInputElement }>({});
  const [renameInputID, setRenameInputID] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateAIModels = () => {
    dispatch(fetchAIModelsStore());
  };

  const tableColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 30,
      cell: (row) => {
        const aiModel = row as MLAnnotationModel;
        const isAIModelSharedByMyTeam =
          aiModel?.owner !== keycloak?.keycloak?.idTokenParsed?.sub;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="flex overflow-hidden pr-3"
          >
            <EditName
              item={row as Record<string, any>}
              inputRefArray={inputRefArray}
              editInputID={renameInputID}
              setEditInputID={setRenameInputID}
              handleRename={(newName: string) =>
                handleRenameAIModel(aiModel?.id, newName)
              }
              withToolTip={true}
              fontSize="text-normal"
            />
            {isAIModelSharedByMyTeam && <SharedByTeamLabel />}
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const aiModel = row as MLAnnotationModel;
        const date = new Date(aiModel?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      span: 20,
      cell: (row) => {
        const aiModel = row as MLAnnotationModel;
        return renderStatusChip(aiModel?.status);
      },
    },
    {
      field: "user_group",
      headerName: "Team",
      span: 20,
      cell: (row) => {
        const aiModel = row as MLAnnotationModel;
        return (
          <div onClick={(e) => e.stopPropagation()} className="w-full">
            <UserGroupSelection
              selectedUserGroup={aiModel?.user_group}
              onChanges={(newUserGroup: string) =>
                patchMLAnnotationModel(
                  aiModel?.id,
                  { user_group: newUserGroup },
                  dispatch,
                  setIsLoading,
                ).then(() => updateAIModels())
              }
            />
          </div>
        );
      },
    },

    {
      field: "",
      headerName: "Actions",
      headerClassName: "justify-end",
      sortable: false,
      span: 10,
      cell: (row) => {
        const aiModel = row as MLAnnotationModel;
        return (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full flex flex-row-reverse justify-start"
          >
            <AIModelManagerTableActions
              mlAnnotationModel={aiModel}
              setRenameInputID={setRenameInputID}
              setConfirmDialogState={setConfirmDialogState}
            />
          </div>
        );
      },
    },
  ];

  const renderStatusChip = (status: MLAnnotationModelStatus) => {
    let color;
    switch (status) {
      case MLAnnotationModelStatus.CREATED:
      case MLAnnotationModelStatus.TRAINING:
        color = "bg-paletteYellow";
        break;
      case MLAnnotationModelStatus.TRAINING_FAILED:
        color = "bg-paletteRed";
        break;
      case MLAnnotationModelStatus.TRAINING_DONE:
        color = "bg-paletteGreen";
        break;
      default:
        color = "bg-paletteGray6";
    }

    return <StatusLabel label={snakeCaseToText(status)} colorClass={color} />;
  };

  const handleRenameAIModel = (aiModelID: string, newName: string) => {
    patchMLAnnotationModel(
      aiModelID,
      { name: newName },
      dispatch,
      setIsLoading,
    ).then(() => updateAIModels());
  };

  const handleDeleteAIModel = () => {
    if (!confirmDialogState.mlAnnotationModelID) return;

    deleteMLAnnotationModel(
      {
        mlAnnotationModelID: confirmDialogState.mlAnnotationModelID,
      },
      dispatch,
      setIsLoading,
    ).then(() => {
      updateAIModels();
      setConfirmDialogState({
        ...confirmDialogState,
        show: false,
        mlAnnotationModelID: null,
      });
    });
  };

  const renderDeleteConfirmationDialog = () => {
    const aiModelName =
      _.find(aiManagerSlice.aiModels, [
        "id",
        confirmDialogState.mlAnnotationModelID,
      ])?.name || "";
    return (
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogState.show}
        setIsConfirmDialogOpen={(newState) =>
          setConfirmDialogState({ ...confirmDialogState, show: newState })
        }
        text={`Are you sure you want to delete the AI model 
                "${aiModelName}"?`}
        confirmButtonText="Confirm"
        handleOnSuccess={() => handleDeleteAIModel()}
      />
    );
  };

  return (
    <>
      <DataTable
        rows={aiManagerSlice.aiModels as []}
        columns={tableColumns}
        defaultSort={{ name: "created_at", direction: "desc" }}
        isLoading={aiManagerSlice.loadingAIModels}
        isDisabled={isLoading}
        searchValue={aiManagerSlice.searchValue}
        onRowClick={(row) => {
          setOverviewMlAnnotationModel(row as MLAnnotationModel);
        }}
      />
      {renderDeleteConfirmationDialog()}
    </>
  );
};

export default AIModelManagerTable;
