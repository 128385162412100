import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addMediaObjectIDToSelectedMediaObjectIDs,
  fetchMediaObjectsData,
  removeMediaObjectIDFromSelectedMediaObjectIDs,
  setMediaObjectExplorationCurrentMediaObject,
  updateMediaObjectsExplorationHasMore,
} from "store/explorationMediaObjectsSlice";
import _ from "lodash";

import {
  MediaObjectRawModel,
  ExplorationAPIRequestModel,
  MediaModel,
} from "models/exploration.model";

import InfiniteScroll from "react-infinite-scroll-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

import getSubsetName from "helpers/functions/getSubsetName";

import { fetchMediaObjectsDataScroll } from "helpers/apis/mediaObjects";

import { fetchOneMediaAPI, fetchOneMediaObjectAPI } from "helpers/apis";
import ThumbnailCard from "components/Exploration/ThumbnailCard";
import { AnnotatableEnum, VisibilityStatus } from "models/global.model";

import ExplorationHeader from "components/Exploration/ExplorationHeader";
import InfoPanel from "components/UtilComponents/InfoPanel";
import convertActiveFilterToSendFilter from "helpers/functions/filters/convertActiveFilterToSendFilter";
import { searchParamsToSort } from "helpers/functions/filters/filtersHelpers";
import { fetchSelectedViewCount } from "helpers/functions/datasets/datasetHelpers";
import { SingleSliderFieldModel } from "models/form.model";
import SubsetCreationLoading from "components/Exploration/SubsetCreationLoading";
import snackbarHelper from "components/Helpers/snackbarHelperFn";

const scrollLoadAmount = Number(
  process.env.REACT_APP_SCROLL_LOAD_AMOUNT ??
    alert("Config value 'scroll load amount' is not defined"),
);

const ExplorationMediaObjects = () => {
  const params: any = useParams();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const selectedView = useAppSelector((state) => state.appSlice.selectedView);
  const explorationMediaObjectsData = useAppSelector(
    (state) => state.explorationMediaObjectsSlice,
  );
  const totalCount = useAppSelector(
    (state) => state.metaDataSlice?.mediaObjectsCount?.total_count,
  );
  const filterData = useAppSelector((state) => state.filterDataSlice);
  const mediaObjectsSortData = useAppSelector(
    (state) => state.sortDataSlice?.activeMediaObjects,
  );
  const dataSetData = useAppSelector((state) => state.datasetSlice);

  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [pinnedIndex, setPinnedIndex] = useState<null | number>(null);

  const [numOfItemPerRow, setNumOfItemPerRow] =
    useState<SingleSliderFieldModel>({
      type: "single_slider",
      key: "sampleSize",
      value: 6,
      min: 4,
      max: 10,
      settings: {
        step: 1,
        stepInteger: true,
        hideUnderSliderLabels: true,
      },
    });
  const [currentMedia, setCurrentMedia] = useState<MediaModel>();

  const currentMediaObjectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    currentMediaObjectRef.current?.scrollIntoView();
  }, [currentMediaObjectRef]);

  // Fetch medias data for exploration and annotations
  // Update when: The URL id or subset_id change
  useEffect(() => {
    if (
      _.isEmpty(explorationMediaObjectsData?.data) &&
      // Need to check if the data is not loading
      // Fix for issue when the data is empty and then apply filter function triggers a fetch for
      //  new data and also update active filter.
      //  The new active filter will trigger this useEffect.
      //  If the previous data was 0, then this will trigger simultaneously with the fetch from the apply filter.
      explorationMediaObjectsData.loading === false &&
      !_.isEmpty(filterData.annotationFilterData)
    ) {
      const APIBody: ExplorationAPIRequestModel = {
        dataset_id: params.id,
        limit: scrollLoadAmount,
        skip: 0,
        query: convertActiveFilterToSendFilter(
          filterData.activeMediaObjectsFilter,
        ),
        sort: searchParamsToSort(),
      };
      dispatch(
        fetchMediaObjectsData({
          runId: params.id,
          reqBody: APIBody,
          subSetId: params?.subset_id,
        }),
      );
      fetchSelectedViewCount(
        {
          selectedView: selectedView,
          filterData: filterData,
          runID: params.id,
          subset_id: params?.subset_id,
        },
        dispatch,
      );
    }
  }, [params?.id, params?.subset_id, filterData.activeMediaObjectsFilter]);

  useEffect(() => {
    if (
      explorationMediaObjectsData?.skip + scrollLoadAmount >= totalCount ||
      totalCount === 0
    ) {
      dispatch(updateMediaObjectsExplorationHasMore(false));
    }
  }, [explorationMediaObjectsData?.data, totalCount]);

  const onMouseOverCard = (object: MediaObjectRawModel, key: number) => {
    setHoveredIndex(key);

    if (_.isNull(pinnedIndex)) {
      fetchMediaByID(object?.media_id);
    }
  };

  const fetchMediaByID = (mediaID: string) => {
    fetchOneMediaAPI(mediaID, params?.id)
      .then((response) => {
        setCurrentMedia(response);
      })
      .catch((error) =>
        snackbarHelper(error?.detail || "Media fetching failed!", "error"),
      );
  };

  const fetchMediaObjectsByMediaID = (objectID: string) => {
    const response = fetchOneMediaObjectAPI(objectID, params?.id);
    return response;
  };

  const handleOnCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    mediaObject: MediaObjectRawModel,
  ) => {
    const newVal = event?.target?.checked;
    if (newVal) {
      dispatch(addMediaObjectIDToSelectedMediaObjectIDs(mediaObject?.id));
    } else {
      dispatch(removeMediaObjectIDFromSelectedMediaObjectIDs(mediaObject?.id));
    }
  };

  const handleOnExpandButtonClick = (mediaObject: MediaObjectRawModel) => {
    if (_.isUndefined(params?.id) || _.isUndefined(params?.subset_id))
      throw new Error("Media or params is undefined");

    dispatch(setMediaObjectExplorationCurrentMediaObject(mediaObject));
    const pushURL = `/main/${params?.id}/${params?.subset_id}/mediaObject/${mediaObject?.id}`;
    history.push(pushURL);
  };
  if (
    dataSetData.activeDataSet?.visibility_status ===
    VisibilityStatus.CreatingSubset
  ) {
    return <SubsetCreationLoading subset={dataSetData.activeDataSet} />;
  }
  return (
    <div className="flex h-full">
      <div className="min-w-0 flex-1 h-full flex flex-col gap-y-4">
        {/* Header section */}
        <ExplorationHeader
          numOfItemPerRow={numOfItemPerRow}
          setNumOfItemPerRow={(newVal) => setNumOfItemPerRow(newVal)}
        />

        {/* Infinite scroll */}
        <div
          className="flex-1 min-h-0 overflow-y-auto"
          id="objects-scrollable-div"
          data-test="object_expl_grid"
        >
          {explorationMediaObjectsData.loading &&
          explorationMediaObjectsData?.hasMore ? (
            <div className="h-full flex justify-center items-center">
              <CircularProgress size={60} />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={explorationMediaObjectsData.data?.length || 0}
              next={() =>
                fetchMediaObjectsDataScroll(
                  dispatch,
                  explorationMediaObjectsData,
                  totalCount,
                  params?.id,
                  params?.subset_id,
                  filterData.activeMediaObjectsFilter,
                  mediaObjectsSortData,
                )
              }
              hasMore={explorationMediaObjectsData?.hasMore}
              loader={
                <div className=" flex justify-center items-center">
                  Loading...
                </div>
              }
              initialScrollY={Math.floor(hoveredIndex / 6) * 115}
              scrollThreshold={0.5}
              scrollableTarget="objects-scrollable-div"
            >
              <div
                className={`h-full grid gap-[1px] grid-cols-${numOfItemPerRow?.value} pr-2`}
              >
                {_.map(
                  explorationMediaObjectsData?.data,
                  (mediaObject, key: number) => {
                    const isSelected = _.includes(
                      explorationMediaObjectsData.selectedMediaObjectsIDs,
                      mediaObject?.id,
                    );

                    const objectCategoryName = getSubsetName(
                      mediaObject?.object_category,
                      dataSetData?.subSets,
                    );
                    const objectCategoryColor = _.find(dataSetData?.subSets, {
                      id: mediaObject?.object_category,
                    })?.color;

                    return (
                      <div
                        key={mediaObject?.id}
                        ref={
                          mediaObject?.id ===
                          explorationMediaObjectsData.currentMediaObject?.id
                            ? currentMediaObjectRef
                            : null
                        }
                        onMouseEnter={() => onMouseOverCard(mediaObject, key)}
                      >
                        <ThumbnailCard
                          type={AnnotatableEnum.MediaObject}
                          item={mediaObject}
                          url={mediaObject?.crop_url}
                          itemIndex={key}
                          isSelected={isSelected}
                          hoveredIndex={hoveredIndex}
                          setHoveredIndex={setHoveredIndex}
                          pinnedIndex={pinnedIndex}
                          setPinnedIndex={setPinnedIndex}
                          onExpandClick={() =>
                            handleOnExpandButtonClick(mediaObject)
                          }
                          onCheckboxClick={(event) =>
                            handleOnCheckboxClick(event, mediaObject)
                          }
                          onItemURLExpire={fetchMediaObjectsByMediaID}
                        />

                        <Tooltip title={objectCategoryName}>
                          <div
                            className="w-auto flex items-center gap-x-1 text-sm"
                            data-test="category_label"
                          >
                            <div
                              className="w-2 h-2 rounded-full"
                              style={{
                                backgroundColor: objectCategoryColor,
                              }}
                            />
                            {_.truncate(objectCategoryName)}
                          </div>
                        </Tooltip>
                      </div>
                    );
                  },
                )}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </div>
      <InfoPanel
        showMediaOrMediaObject={AnnotatableEnum.MediaObject}
        currentMediaObject={
          explorationMediaObjectsData.data?.[
            !_.isNull(pinnedIndex) ? pinnedIndex : hoveredIndex
          ]
        }
        currentMedia={currentMedia}
        fetchMediaAndMediaObjects={fetchMediaByID}
      />
    </div>
  );
};

export default ExplorationMediaObjects;
