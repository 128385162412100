import ActionsBar from "components/DetailsScreen/ActionBar";
import { MediaDetailsScreenRouteModel } from "models/routes.model";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchOneMediaAPI, fetchMediaObjects } from "helpers/apis";
import { MediaModel, MediaObjectModel } from "models/exploration.model";
import _ from "lodash";
import { AnnotatableEnum } from "models/global.model";
import ThumbnailsCarousel from "components/DetailsScreen/ThumbnailsCarousel";
import { setMediaExplorationCurrentMedia } from "store/explorationMediaSlice";
import HermesMediaViewer from "components/DetailsScreen/HermesMediaViewer";
import { fetchMediaDataScroll, fetchMedias } from "helpers/apis/medias";
import InfoPanel from "components/UtilComponents/InfoPanel";
import { convertMediaObjectsRawModelToMediaObjectModel } from "helpers/functions/convertMediaObjectsRawModelToMediaObjectModel";
import { fetchTags } from "store/datasetSlice";
import { Mediatype } from "models/dataset.model";
import isURLStillValid from "helpers/functions/isURLStillValid";
import {
  fetchActiveDataset,
  fetchDatasetSliceData,
} from "helpers/functions/datasets/datasetHelpers";
import LidarViewerContainer from "components/DetailsScreen/LidarViewerContainer";
import getObjectGeometryOfCrop from "helpers/functions/hermes2dPainter/getObjectGeometryOfCrop";
import selectMediaObjectURL from "helpers/functions/hermes2dPainter/selectMediaObjectURL";
import { filterGeometriesBasedOnToggledCategories } from "helpers/functions/detailsScreenHelper";
import snackbarHelper from "components/Helpers/snackbarHelperFn";

const MediaDetailsScreen = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const params: MediaDetailsScreenRouteModel = useParams();

  const dataSetData = useAppSelector((state) => state.datasetSlice);
  const mediatype = useAppSelector(
    (state) => state.datasetSlice.activeDataSet?.mediatype,
  );
  const subSets = useAppSelector((state) => state.datasetSlice.subSets);
  const explorationMediaData = useAppSelector(
    (state) => state.explorationMediaSlice,
  );
  const totalCount = useAppSelector(
    (state) => state.metaDataSlice?.mediaCount?.total_count,
  );
  const mediaFilterData = useAppSelector(
    (state) => state.filterDataSlice.activeMediaFilter,
  );
  const mediaSortData = useAppSelector(
    (state) => state.sortDataSlice?.activeMedia,
  );
  const detailsScreenSlice = useAppSelector(
    (state) => state.detailsScreenSlice,
  );
  const tags = useAppSelector((state) => state.datasetSlice?.tags);

  const [showMediaOrMediaObject, setShowMediaOrMediaObject] = useState<
    AnnotatableEnum.Media | AnnotatableEnum.MediaObject
  >(AnnotatableEnum.Media);

  const [currentMedia, setCurrentMedia] = useState<MediaModel>();
  const [currentMediaIndex, setCurrentMediaIndex] = useState(
    _.findIndex(
      explorationMediaData?.data,
      (media) => media?.id === explorationMediaData.currentMedia?.id,
    ) || 0,
  );

  const [currentMediaObject, setCurrentMediaObject] =
    useState<MediaObjectModel>();
  const [currentMediaObjects, setCurrentMediaObjects] = useState<
    MediaObjectModel[]
  >([]);
  // Extra media objects are the 2d objects of the current media in the 3D datasets
  const [currentExtraMediaObjects, setCurrentExtraMediaObjects] = useState<
    MediaObjectModel[]
  >([]);
  const [hoveredBBID, setHoveredBBID] = useState("");
  const [showLabel, setShowLabel] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchActiveDataset(dispatch, {
      datasetId: params?.id,
      subset_id: params?.subset_id,
    });
  }, [params?.id, params?.subset_id]);

  useEffect(() => {
    fetchDatasetSliceData(dispatch, dataSetData);
  }, [dataSetData?.activeDataSet]);

  // Set the new media when currentMedia change
  // (when assign/unassign a tag)
  useEffect(() => {
    if (explorationMediaData.currentMedia) {
      setCurrentMedia(explorationMediaData.currentMedia);
      get2DObjectsGeometriesIn3DDatasets(explorationMediaData.currentMedia);
    }
  }, [explorationMediaData.currentMedia]);

  //Initialize tags
  useEffect(() => {
    if (_.isEmpty(tags))
      dispatch(fetchTags({ query: { parentDataSetID: params?.id } }));
  }, []);

  // Fetch the image if no currentImage.
  // Fetch the image objects of the image.
  useEffect(() => {
    setCurrentMedia(undefined);
    setCurrentMediaObject(undefined);
    setCurrentMediaObjects([]);
    setCurrentExtraMediaObjects([]);
    if (!_.isNull(explorationMediaData.currentMedia)) {
      setCurrentMedia(explorationMediaData.currentMedia);
    } else {
      fetchMediaByMediaName();
    }

    // Get media objects for the media
    const timeoutID = setTimeout(() => {
      fetchMediaObjectsByMediaID();
    }, 300);
    return () => clearTimeout(timeoutID);
  }, [params.id, params.media_id]);

  const fetchMediaByMediaName = () => {
    fetchOneMediaAPI(params?.media_id, params?.id)
      .then((response) => {
        setCurrentMedia(response);
      })
      .catch((error) =>
        snackbarHelper(error?.detail || "Media fetching failed!", "error"),
      );
  };

  const fetchMediaObjectsByMediaID = () => {
    setIsLoading(true);
    fetchMediaObjects({
      dataset_id: params?.id,
      query: [
        {
          attribute: "media_id",
          query_operator: "==",
          value: params?.media_id,
        },
      ],
    })
      .then((response) => {
        const newBBs = convertMediaObjectsRawModelToMediaObjectModel(
          response,
          subSets,
        );
        setCurrentMediaObjects(newBBs);
        // Update the current media object if it defined
        if (!_.isUndefined(currentMediaObject)) {
          const newBB = _.find(newBBs, { id: currentMediaObject?.id });
          setCurrentMediaObject(newBB);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        snackbarHelper(
          error?.detail || "Media object(s) fetching failed!",
          "error",
        );
        setIsLoading(false);
      });
  };

  // Update when currentMediaIndex change.
  // Push a new url with the the next media name.
  // Check if the is the last index, if so fetch the next batch of medias.
  useEffect(() => {
    if (
      // If the current media is found in the list of medias
      // (when we refresh the page we don't want to fetch medias)
      !_.isUndefined(currentMediaIndex) &&
      currentMediaIndex !== -1 &&
      // If the current media is the last media in the list
      currentMediaIndex + 1 + 1 > explorationMediaData.data?.length
    ) {
      fetchMediaDataScroll(
        dispatch,
        explorationMediaData,
        totalCount,
        params?.id,
        params?.subset_id,
        mediaFilterData,
        mediaSortData,
      );
    }
    const newMedia = explorationMediaData.data?.[currentMediaIndex];
    if (newMedia?.id) {
      dispatch(setMediaExplorationCurrentMedia(newMedia));
      history.push(
        `/main/${params?.id}/${params?.subset_id}/media/${newMedia?.id}`,
      );
    }
  }, [currentMediaIndex]);

  useEffect(() => {
    const url = selectURLtoView();
    if (url === undefined) {
      return;
    }

    if (isURLStillValid(url)) {
      return;
    } else {
      showMediaOrMediaObject === AnnotatableEnum.Media &&
        fetchMediaByMediaName();
    }
  }, [showMediaOrMediaObject, params?.media_id]);

  // Fetch the 2D objects geometries in 3D datasets
  //  First we fetch the RGB medias of the current scene and frame
  //  Then we fetch the 2D objects geometries of the RGB medias
  const get2DObjectsGeometriesIn3DDatasets = (pcMedia: MediaModel) => {
    if (
      pcMedia &&
      pcMedia?.media_type === Mediatype.point_cloud &&
      pcMedia?.scene_id &&
      pcMedia?.frame_idx !== undefined
    ) {
      fetchMedias({
        dataset_id: pcMedia?.dataset_id,
        query: [
          {
            attribute: "scene_id",
            query_operator: "==",
            value: pcMedia?.scene_id,
          },
          {
            attribute: "frame_idx",
            query_operator: "==",
            value: pcMedia?.frame_idx,
          },
        ],
      }).then((rgbMedias) => {
        const cameraIDs = _.map(
          _.filter(
            rgbMedias,
            (camera) => camera.media_type !== Mediatype.point_cloud,
          ),
          (camera) => camera.id,
        );

        if (cameraIDs.length === 0) return;

        // Fetch the 2D objects geometries of the RGB medias
        fetchMediaObjects({
          dataset_id: params?.id,
          query: [
            {
              attribute: "media_id",
              query_operator: "in",
              value: cameraIDs,
            },
          ],
        }).then((MediaObjects2D) => {
          const newBBs = convertMediaObjectsRawModelToMediaObjectModel(
            MediaObjects2D,
            subSets,
          );
          setCurrentExtraMediaObjects(newBBs);
        });
      });
    }
  };

  const selectURLtoView = () => {
    if (dataSetData?.activeDataSet === null) return;

    if (
      showMediaOrMediaObject === AnnotatableEnum.Media &&
      !_.isUndefined(currentMedia)
    ) {
      return currentMedia?.media_url;
    }
    if (
      showMediaOrMediaObject === AnnotatableEnum.MediaObject &&
      !_.isUndefined(currentMediaObject)
    ) {
      return selectMediaObjectURL(
        currentMediaObject,
        dataSetData.VisualisationConfiguration,
        dataSetData?.activeDataSet.id,
      );
    }
  };

  const handleOnMediaObjectClick = (clickedMediaObjectID: string) => {
    const clickedMediaObject = _.find(
      [...currentMediaObjects, ...currentExtraMediaObjects],
      (mediaObject) => mediaObject?.id === clickedMediaObjectID,
    );
    // Deselect media object
    if (currentMediaObject?.id === clickedMediaObject?.id) {
      setCurrentMediaObject(undefined);
      setShowMediaOrMediaObject(AnnotatableEnum.Media);
    }
    // Select media object
    else {
      setCurrentMediaObject(clickedMediaObject);
      setShowMediaOrMediaObject(AnnotatableEnum.MediaObject);
    }
  };

  const renderSelectedMediaViewer = () => {
    if (!currentMedia) return null;
    if (
      currentMedia?.media_type === Mediatype.image ||
      currentMedia?.media_type === Mediatype.video
    ) {
      return renderMediaViewer();
    } else if (currentMedia?.media_type === Mediatype.point_cloud) {
      return renderPointCloudViewer();
    } else {
      return (
        <div className="w-full h-full">
          Media type ${currentMedia?.media_type} is not supported!
        </div>
      );
    }
  };

  function renderMediaViewer() {
    const mediaObjects = [...currentMediaObjects, ...currentExtraMediaObjects];
    return (
      <HermesMediaViewer
        media_url={selectURLtoView() || ""}
        nextImageUrls={[
          explorationMediaData.data?.[currentMediaIndex + 1]?.media_url,
          explorationMediaData.data?.[currentMediaIndex + 2]?.media_url,
        ]}
        mediaObjectGeometries={
          showMediaOrMediaObject === AnnotatableEnum.Media
            ? isLoading
              ? null
              : filterGeometriesBasedOnToggledCategories(
                  mediaObjects,
                  detailsScreenSlice.objectCategories,
                )
            : []
        }
        allGeometries={mediaObjects}
        baseShapeGeometries={geometriesInImageObjectView()}
        hoveredBBID={hoveredBBID}
        setHoveredBBID={setHoveredBBID}
        showLabel={showMediaOrMediaObject === AnnotatableEnum.Media}
        setShowLabel={setShowLabel}
        onMediaObjectClick={handleOnMediaObjectClick}
      />
    );
  }

  function renderPointCloudViewer() {
    if (!currentMedia) return null;

    return (
      <LidarViewerContainer
        pcMedia={currentMedia}
        showOnlyOneLabel={{
          show: showMediaOrMediaObject === AnnotatableEnum.MediaObject,
          labelId: currentMediaObject?.id || "",
        }}
        hoveredLabelId={hoveredBBID}
      />
    );
  }

  const geometriesInImageObjectView = () => {
    if (dataSetData?.activeDataSet === null) return [];

    if (
      showMediaOrMediaObject === AnnotatableEnum.MediaObject &&
      currentMediaObject
    ) {
      const cropGeometry = getObjectGeometryOfCrop(
        currentMediaObject,
        dataSetData.VisualisationConfiguration,
        dataSetData?.activeDataSet.id,
      );
      if (cropGeometry) {
        return [...cropGeometry];
      }
    }
    return [];
  };

  return (
    <div className="w-full h-full pt-3 flex bg-white">
      {/* Main body */}
      <div className="h-full min-w-0 flex-1 flex flex-col">
        {/* Top action bar */}
        <ActionsBar
          currentItemIndex={currentMediaIndex}
          setCurrentItemIndex={setCurrentMediaIndex}
          currentMediaObjects={[
            ...currentMediaObjects,
            ...currentExtraMediaObjects,
          ]}
        />

        {/* Media viewer */}
        <div className="w-full min-h-0 flex-1">
          {renderSelectedMediaViewer()}
        </div>

        {/* Carousel */}
        <div
          className={`h-auto w-[700px] mx-auto my-2
              ${mediatype === Mediatype.video && "hidden"}`}
        >
          <ThumbnailsCarousel
            media={currentMedia}
            currentMediaIndex={currentMediaIndex}
            onMediaClick={() => {
              setShowMediaOrMediaObject(AnnotatableEnum.Media);
            }}
            mediaObjects={[...currentMediaObjects, ...currentExtraMediaObjects]}
            onMediaObjectHover={(hoveredMediaObject) => {
              setHoveredBBID(hoveredMediaObject?.id);
              setShowLabel(false);
            }}
            onMediaObjectClick={(clickedMediaObjectID: string) =>
              handleOnMediaObjectClick(clickedMediaObjectID)
            }
            selectedItemID={
              showMediaOrMediaObject === AnnotatableEnum.Media
                ? currentMedia?.id || ""
                : currentMediaObject?.id || ""
            }
            renderAllTooltips={true}
          />
        </div>
      </div>

      {/* Info Panel */}
      <InfoPanel
        showMediaOrMediaObject={showMediaOrMediaObject}
        currentMedia={currentMedia}
        currentMediaObject={currentMediaObject}
        fetchMediaObjectsByMediaID={fetchMediaObjectsByMediaID}
      />
    </div>
  );
};

export default MediaDetailsScreen;
