import { APIPostWithBodyAxios } from "components/UtilComponents/Auth";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { AppDispatch } from "store/index";
import { History } from "history";
import { allUsersRoutes } from "routes";
import { getExplorationMediaPageRoute } from "routes/routesHelper";
import { resetStoreOnSubsetChange } from "store/util/resetStore";
import { fetchActiveDataset } from "helpers/functions/datasets/datasetHelpers";
import { VisibilityStatus } from "models/global.model";
import _ from "lodash";
import { matchPath } from "react-router-dom";
import { ExplorationScreenRouteModel } from "models/routes.model";

const webSocketUrl =
  process.env.REACT_APP_WEBSOCKET_URL ??
  alert("Environment variable 'REACT_APP_WEBSOCKET_URL' is undefined");

interface WebSocketConnection {
  id: string;
  user_id: string;
}

enum EventType {
  SUBSET_CREATION_DONE = "subset_creation_done",
}

interface SubsetCreationDonePayload {
  dataset_id: string;
  subset_id: string;
  subset_name: string;
  success: boolean;
  details: string;
}

interface WebSocketMessage {
  type: EventType;
  content: SubsetCreationDonePayload;
  timestamp: string; // UTC timestamp
}

/**
 * An endpoint to create a websocket connection entity
 * @param userID The ID of the user to create a websocket for
 * @returns A websocket connection entity
 */
export const postWebSocketConnectionCreate = async (
  userID: string,
): Promise<WebSocketConnection> => {
  const response = await APIPostWithBodyAxios(`/websocketConnections`, {
    user_id: userID,
  });
  return response?.data;
};

/**
 * A helper function to create a websocket object
 * @param userID The user ID of the user to create a websocket for
 * @param dispatch The AppDispatch object
 * @returns The websocket object
 */
export const createWebsocket = async (
  userID: string,
  dispatch: AppDispatch,
  history: History,
) => {
  try {
    const response = await postWebSocketConnectionCreate(userID);
    return connectWebsocket(userID, response, dispatch, history);
  } catch (error) {
    console.error("WebSocket connection creation failed");
    return await Promise.reject();
  }
};

const connectWebsocket = (
  userID: string,
  wsConnection: WebSocketConnection,
  dispatch: AppDispatch,
  history: History,
): WebSocket => {
  const ws = new WebSocket(
    `${webSocketUrl}/?user_id=${userID}&id=${wsConnection.id}`,
  );
  ws.onmessage = (event: MessageEvent<string>) => {
    const webSocketMessage = JSON.parse(event.data) as WebSocketMessage;

    switch (webSocketMessage.type) {
      case EventType.SUBSET_CREATION_DONE:
        if (webSocketMessage.content.success) {
          // raise a snackbar until we have a better notification system
          // use "info" severity to differentiate from backend calls
          snackbarHelper(
            `Subset '${webSocketMessage.content.subset_name}' was created successfully!`,
          );

          handleSubsetCreationSuccess(
            webSocketMessage.content.dataset_id,
            webSocketMessage.content.subset_id,
            dispatch,
            history,
          );
        } else {
          // raise a snackbar until we have a better notification system
          // use "warning" severity to differentiate from backend calls
          snackbarHelper(
            `Subset '${webSocketMessage.content.subset_name}' could not be created.\n
              Reason: ${webSocketMessage.content.details}`,
            "warning",
          );
        }
        break;
      default:
        console.error("Unknown event type");
    }
  };

  ws.onopen = () => {
    // Send a keep-alive message every 5 minutes
    setInterval(
      () => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "keep-alive" }));
        }
      },
      60 * 5 * 1000,
    );
  };

  ws.onerror = () => {
    //
  };

  ws.onclose = () => {
    //
  };

  return ws;
};

const handleSubsetCreationSuccess = (
  datasetID: string,
  subsetID: string,
  dispatch: AppDispatch,
  history: History,
) => {
  const route = history.location.pathname;
  const matchState = {
    exact: true,
    strict: false,
  };

  // Check if the current route is an exploration page
  const mediaMatch = matchPath(route, {
    path: allUsersRoutes.explorationMediaPage.path,
    ...matchState,
  });
  const objectMatch = matchPath(route, {
    path: allUsersRoutes.explorationObjectPage.path,
    ...matchState,
  });
  const instanceMatch = matchPath(route, {
    path: allUsersRoutes.explorationInstancePage.path,
    ...matchState,
  });

  let match = mediaMatch;
  if (match === null) {
    match = objectMatch;
  }
  if (match === null) {
    match = instanceMatch;
  }

  // If the current route is an exploration page, reset the store and fetch the new subset
  if (match !== null) {
    const matchParams: ExplorationScreenRouteModel =
      match.params as ExplorationScreenRouteModel;
    if (matchParams.id === datasetID && matchParams.subset_id === subsetID) {
      resetStoreOnSubsetChange(dispatch);
      fetchActiveDataset(dispatch, {
        datasetId: datasetID,
        subset_id: subsetID,
        visibility_statuses: [
          VisibilityStatus.Visible,
          VisibilityStatus.CreatingSubset,
        ],
      });
    }
  }
};
