import React, { ChangeEvent, useEffect, useState } from "react";

import Header from "components/UploadManagement/Header";
import DataTable, {
  DataTableColumn,
} from "components/Internal/Table/DataTable";
import {
  fetchUploadJobsAPI,
  updateUploadJobsAPI,
} from "helpers/apis/uploadJobs";
import {
  UploadJobsModel,
  UploadProcessingStatus,
} from "models/uploadJobs.model";
import { fetchImportJobsAPI, postImportJobsAPI } from "helpers/apis/importJobs";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ImportJobsModel } from "models/importJobs.model";
import { fetchDashboardInformation } from "store/dashboardSlice";
import { useKeycloak } from "@react-keycloak/web";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import snackbarHelper from "components/Helpers/snackbarHelperFn";
import { snakeCaseToText } from "components/utilFunctions";
import _ from "lodash";
import TooltipTruncate from "components/Internal/Tooltips/TooltipTruncate";
import { VisibilityStatus } from "models/global.model";
import TooltipTruncateEllipsis from "components/Internal/Tooltips/TooltipTruncateEllipsis";

export enum UploadManagementScreenTabs {
  Uploads = "Uploads",
  Imports = "Imports",
}

const UploadManagement = () => {
  const dispatch = useAppDispatch();

  const dashboardData = useAppSelector((state) => state.dashboardSlice);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<UploadManagementScreenTabs>(
    UploadManagementScreenTabs.Uploads,
  );

  const [uploadJobs, setUploadJobs] = useState<UploadJobsModel[]>([]);
  const [importJobs, setImportJobs] = useState<ImportJobsModel[]>([]);
  const [selectedUploadJob, setSelectedUploadJob] = useState<UploadJobsModel>();
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [importForm, setImportForm] = useState({
    title: "",
    name: "",
    userGroup: "",
    compress: false,
    anonymize: undefined,
  });

  const { keycloak } = useKeycloak();

  useEffect(() => {
    fetchJobsData();
  }, []);

  const fetchJobsData = () => {
    dispatch(
      fetchDashboardInformation({
        visibility_statuses: [
          VisibilityStatus.Visible,
          VisibilityStatus.Importing,
        ],
      }),
    );
    (async () => {
      const response = await fetchUploadJobsAPI(dispatch);
      setUploadJobs(response?.data);
    })();

    (async () => {
      const response = await fetchImportJobsAPI(dispatch);
      setImportJobs(response?.data);
    })();
  };

  const handleOpenImportDialog = (uploadJob: UploadJobsModel) => {
    setSelectedUploadJob(uploadJob);
    setImportForm({
      title: uploadJob?.name,
      name: "",
      userGroup: "",
      compress: false,
      anonymize: undefined,
    });
    setOpenImportDialog(true);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setImportForm({ ...importForm, [name]: value });
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setImportForm({
      ...importForm,
      [name]: value === "true",
    });
  };

  const handleSubmitImportForm = () => {
    if (importForm.name.length >= 3 && !_.isUndefined(selectedUploadJob)) {
      if (
        _.find(dashboardData?.data, {
          name: importForm.name,
          parent_dataset: null,
        })
      ) {
        snackbarHelper("Name already exists", "error");
        return;
      }

      // This is required to ensure type safety for the post
      if (
        importForm?.name?.length < 3 ||
        importForm.anonymize === undefined ||
        importForm.compress === undefined
      ) {
        snackbarHelper(
          "Please choose one option for each field first.",
          "error",
        );
        return;
      }

      setIsLoading(true);
      postImportJobsAPI(
        selectedUploadJob.id,
        importForm.userGroup,
        importForm.name,
        importForm.compress,
        importForm.anonymize,
      )
        .then(() => {
          fetchJobsData();
          setOpenImportDialog(false);
          setIsLoading(false);
        })
        .catch((err) => {
          const message = err?.response?.data?.detail;
          snackbarHelper(message, "error");
          setIsLoading(false);
        });
    }
  };

  const uploadColumns: DataTableColumn[] = [
    {
      field: "name",
      headerName: "Name",
      span: 20,
    },
    {
      field: "status",
      headerName: "Status",
      span: 10,
      cell: (row) => {
        const uploadJob = row as UploadJobsModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {snakeCaseToText(uploadJob?.status)}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      span: 15,
      cell: (row) => {
        const rowTyped = row as UploadJobsModel;
        const date = new Date(rowTyped?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Last updated",
      span: 15,
      cell: (row) => {
        const rowTyped = row as UploadJobsModel;
        const date = new Date(rowTyped?.updated_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {!_.isNull(rowTyped?.updated_at)
              ? date.toLocaleString()
              : "-/-/-, --:--:--"}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "user_groups",
      headerName: "User group",
      span: 20,
      cell: (row) => {
        const rowTyped = row as UploadJobsModel;
        const userGroups = keycloak?.idTokenParsed?.user_groups;

        const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
          rowTyped.user_groups = event.target.value as string[];
          updateUploadJobsAPI(dispatch, rowTyped).then(() => {
            fetchJobsData();
          });
        };
        return (
          <div className="w-5/6" key={rowTyped?.id}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="user-groups-select">User group</InputLabel>
              <Select
                key={`select-${rowTyped?.id}`}
                //Only allow user to change user groups if they are the owner of the upload job
                disabled={
                  rowTyped.user_name !=
                  keycloak?.idTokenParsed?.preferred_username
                }
                multiple
                label="User groups"
                value={rowTyped.user_groups || []}
                onChange={(e: any) => handleChange(e)}
                inputProps={{
                  name: "user_groups",
                  id: "user-groups-select",
                }}
              >
                {userGroups?.map((group: string) => (
                  <MenuItem key={group} value={group}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      sortable: false,
      span: 20,
      cell: (row) => {
        const rowTyped = row as UploadJobsModel;
        return (
          <div className="flex w-full items-center justify-end">
            <button
              className="button-layer"
              data-test="import_as_dataset"
              disabled={rowTyped?.status !== UploadProcessingStatus.done}
              onClick={() => handleOpenImportDialog(rowTyped)}
            >
              Import as dataset
            </button>
          </div>
        );
      },
    },
  ];

  const importColumns: DataTableColumn[] = [
    {
      field: "dataset_id",
      headerName: "Dataset",
      span: 30,
      cell: (row) => {
        const importJob = row as ImportJobsModel;
        const datasetName = _.find(dashboardData?.data, [
          "id",
          importJob?.dataset_id,
        ])?.name;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {datasetName ?? ""}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      span: 15,
      cell: (row) => {
        const importJob = row as ImportJobsModel;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {snakeCaseToText(importJob?.status)}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "dataset_upload_job_id",
      headerName: "Upload job",
      span: 15,
      cell: (row) => {
        const importJob = row as ImportJobsModel;
        const uploadJobName = _.find(uploadJobs, [
          "id",
          importJob?.dataset_upload_job_id,
        ])?.name;
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {uploadJobName ?? ""}
          </TooltipTruncateEllipsis>
        );
      },
    },

    {
      field: "created_at",
      headerName: "Created",
      span: 20,
      cell: (row) => {
        const rowTyped = row as UploadJobsModel;
        const date = new Date(rowTyped?.created_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {date.toLocaleString()}
          </TooltipTruncateEllipsis>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Last updated",
      span: 20,
      cell: (row) => {
        const rowTyped = row as UploadJobsModel;
        const date = new Date(rowTyped?.updated_at);
        return (
          <TooltipTruncateEllipsis className="pr-3">
            {!_.isNull(rowTyped?.updated_at)
              ? date.toLocaleString()
              : "-/-/-, --:--:--"}
          </TooltipTruncateEllipsis>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-white">
      <div className="h-full flex flex-col">
        <div className="h-auto">
          <Header
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            refreshData={fetchJobsData}
          />
        </div>
        <div className="flex-1 min-h-0 pb-4">
          <DataTable
            rows={
              selectedTab == UploadManagementScreenTabs.Uploads
                ? (uploadJobs as [])
                : (importJobs as [])
            }
            columns={
              selectedTab == UploadManagementScreenTabs.Uploads
                ? uploadColumns
                : importColumns
            }
          />
        </div>
        <Dialog
          open={openImportDialog}
          onClose={handleCloseImportDialog}
          fullWidth={true}
        >
          <DialogTitle>Import dataset "{importForm.title}"</DialogTitle>
          <DialogContent>
            <Box marginBottom={2} marginTop={2}>
              <FormControl fullWidth>
                <div>Name</div>
                <input
                  className="input-text"
                  data-test="import_name"
                  value={importForm.name}
                  onChange={(event) => {
                    setImportForm({
                      ...importForm,
                      ["name"]: event.target.value,
                    });
                  }}
                  placeholder="At least 3 characters"
                  title={
                    importForm.name.length < 3
                      ? "Name must be at least 3 characters"
                      : ""
                  }
                />
              </FormControl>
            </Box>
            {keycloak?.idTokenParsed?.user_groups?.length > 0 && (
              <Box marginBottom={2} marginTop={2}>
                <FormControl fullWidth>
                  <div>User group</div>
                  <Select
                    labelId="userGroup-select-label"
                    name="userGroup"
                    data-test="import_group"
                    value={importForm.userGroup}
                    onChange={(e: any) => {
                      handleInputChange(e);
                    }}
                  >
                    <MenuItem key={"empty"} value={""}>
                      {"None"}
                    </MenuItem>
                    {keycloak?.idTokenParsed?.user_groups.map(
                      (group: string) => (
                        <MenuItem key={group} value={group}>
                          {group}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Box marginBottom={2} marginTop={2}>
              <div style={{ marginBottom: "16px" }}>Data anonymization</div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "auto",
                }}
              >
                <label
                  style={{
                    marginBottom: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    name="anonymize"
                    className="checkbox-layer"
                    value="false"
                    checked={importForm.anonymize === false}
                    onChange={(e) => handleRadioChange(e)}
                    style={{ transform: "scale(1.3)", marginRight: "10px" }}
                  />
                  <Tooltip
                    title="Choose this if you have already anonymized your data
                    yourself and/or do not wish Quality Match to do so. You understand
                     that our company is not legally responsible for personal data
                      visible in your dataset."
                    placement="right"
                  >
                    <span>
                      I have already anonymized my data and/or do not wish
                      Quality Match to anonymize it
                    </span>
                  </Tooltip>
                </label>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    name="anonymize"
                    className="checkbox-layer"
                    value="true"
                    checked={importForm.anonymize === true}
                    onChange={(e) => handleRadioChange(e)}
                    style={{ transform: "scale(1.3)", marginRight: "10px" }}
                  />
                  <Tooltip
                    title="Choose this if you want us to anonymize your data for you.
                    Please note this might take a while."
                    placement="right"
                  >
                    <span>Request anonymization by Quality Match</span>
                  </Tooltip>
                </label>
              </div>
            </Box>
            {/* keeping this as we might need it soon again
            <Box marginBottom={2} marginTop={3}>
              <div style={{ marginBottom: "16px" }}>Data compression</div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label
                  style={{
                    marginBottom: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="radio"
                    name="compress"
                    className="checkbox-layer"
                    value="false"
                    checked={importForm.compress === false}
                    onChange={(e) => handleRadioChange(e)}
                    style={{ transform: "scale(1.3)", marginRight: "10px" }}
                  />
                  <Tooltip
                    title="Your images are less than 6MB large. We will use your media as is."
                    placement="right"
                  >
                    <span>Image size is less than 6 mb</span>
                  </Tooltip>
                </label>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="radio"
                    name="compress"
                    className="checkbox-layer"
                    value="true"
                    checked={importForm.compress === true}
                    onChange={(e) => handleRadioChange(e)}
                    style={{ transform: "scale(1.3)", marginRight: "10px" }}
                  />
                  <Tooltip
                    title="At least some of your images are more than 6MB large.
                    We will compress your media to stay below 6MB.
                    This is necessary to maintain performant quality assurance."
                    placement="right"
                  >
                    <span>Image size is bigger than 6 mb</span>
                  </Tooltip>
                </label>
              </div>
            </Box>*/}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseImportDialog}
              className="button-select-layer w-auto upperfirst"
            >
              Cancel
            </Button>
            <button
              disabled={
                isLoading ||
                importForm?.name?.length < 3 ||
                importForm.anonymize === undefined ||
                importForm.compress === undefined
              }
              onClick={handleSubmitImportForm}
              className="button-dark-gray-layer w-auto"
            >
              {isLoading ? "Loading..." : "Import"}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default UploadManagement;
